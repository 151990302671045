$nearblack: #181425;
$darkgray: #262b44;
$mediumgray: #5a6988;
$lightgray: #c0cbdc;
$blue: #0095e9;
$darkblue: #124e89;
$green: #63c74d;
$red: #e43b44;
$text-color: #ffffff;
$link-color: #0095e9;
$maxwidth: 1150px;
$padding: 25px;

@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i|Roboto+Mono:400,400i,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Share+Tech+Mono|Share:400,400i,700,700i&display=swap');

@font-face {
    font-family: "Gravity Bold";
    src: url("/fonts/GravityBold8.woff") format("woff"),
        url("/fonts/GravityBold8.ttf") format("ttf");
}

body { font-family: "Share", sans-serif; }
code, .dialog { font-family: "Share Tech Mono", monospace; }
.gravity { font-family: "Gravity Bold" }

html { height: 100% }

body {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16pt;
    background-color: $nearblack;
    color: $text-color;
    margin: 0;
    height: 100%;

    @media screen and (max-width: 767px){
        display: block;
    }
}

h1,h2,h3,h4 {
    font-family: "Gravity Bold", sans-serif;
    font-weight: normal;
    color: $red;
}
h1 { font-size: 24pt; }
h2 { font-size: 20pt; }
h3 { font-size: 14pt; }
h4 { font-size: 14pt; }

figure {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    
    h1,h2,h3,h4 {
        font-family: inherit;
        font-size: 80%;
        font-style: italic;
        font-weight: normal;
        color: inherit;
        margin: 0;
        text-align: center;
    }

    img {
        width: 100%;
    }
}

a {
    color: $link-color;

    &:hover {
        color: $red;
    }
}

img {
    max-width: 100%;
}

video {
    max-width: 100%;
}

li {
    list-style-image: url(/images/bullet.png);
    list-style-position: outside;
}

div#bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -100;

    video {
        position: absolute;
        max-width: 1000%;
        top: 0;
        left: 0;

        @media (min-aspect-ratio: 16/9) {
            width: 100%;
            height: auto;
        }
          
        @media (max-aspect-ratio: 16/9) {
            width: auto;
            height: 100%;
        }
    }
}

input {
    padding: 5px 10px;
    margin: 0;
    box-sizing: content-box;
    font-size: 80%;
}

input[type="text"], input[type="email"] {
    position: relative;
    border: 1px solid #fff;
    border-radius: 3px;
    background-color: #fff;

}

input[type="submit"], input[type="reset"], button {
    border: 1px solid $blue;
    border-radius: 3px;
    background: $blue;
    color: #fff;
    text-decoration: none;
    font-size: 80%;
}

div.content {
    text-align: center;
    width: 100%;
    max-width: $maxwidth;
    padding: 0 $padding;
    box-sizing: border-box;
    flex-grow: 1;
}

.blue { color: $blue }
.red { color: $red }
.green { color: $green }

.center { text-align: center; }
.left { text-align: left; }
.right { text-align: right; }

.dialog {
    .actor {
        text-align: center;
        font-weight: bold;
    }

    .emotion {
        text-align: center;
    }

    .text {
        white-space: pre-line;
    }
}

.floatright {
    float: right;
    margin-left: 25px;
}

.floatleft {
    float: left;
    margin-right: 25px;
}

hr {
    background: url(/images/divider.png) no-repeat center;
    border: none;
    height: 8px;
    margin: 20px 0;
    width: 100%;
}

nav.top {
    position: sticky;
    top: 0;
    font-size: 150%;
    text-align: right;
    padding: 10px 25px 30px 25px;
    background: linear-gradient(rgba(0,0,0,0.8) 40%, rgba(0,0,0,0.0) 100%);
    width: 100%;
    z-index: 100;
    box-sizing: border-box;
    text-transform: uppercase;

    a {
        margin-left: 30px;
    }

    .logo {
        position: absolute;
        left: 25px;
        top: 0px;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}

header {
    padding: $padding $padding 0 $padding;

    .title {
        width: 90vh;
        margin: 20px 0;
        height: auto;
    }

    .release-date {
        font-size: 250%;
        font-weight: bold;
        text-align: center;
    }
}

body.home header {
    margin: 0;
    padding: 0;
    text-align: center;

    .arrow {
        @media screen and (orientation: portrait) {
            display: none;
        }
    }
}

footer {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin: 50px 0 0 0;
    padding: 50px $padding 25px $padding;
    background: rgba(0,149,233,0.7);
    box-sizing: border-box;

    color: #fff;
    a { color: #fff; }

    .socials {
        img {
            height: 32px;
            width: auto;
            margin: 0 20px;
            vertical-align: top;

            @media screen and (max-width: 767px) {
                margin: 0 10px;
            }
        }

        .discord img {
            height: 36px;
        }

        .twitch img {
            height: 36px;
        }
    }

    nav {
    }

    .logo {
        text-align: center;

        img {
            width: 200px;
            height: auto;
        }
    }
}

body.home {
    font-size: 150%;
    
    h2 {
        margin: 50px 0 0 0;
    }
    
    b, strong {
        color: $blue;
        font-weight: bold;
    }

    .trailer {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .about {
        text-align: left;
    }

    .devlog {
        font-size: 120%;
    }
    
    footer .follow { width: 100% }
}

div.cta {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    iframe {
        margin: 10px;

        @media screen and (max-width: 767px) {
            margin: 0;
        }
    }
}

section.post {
    color: $lightgray;
    text-align: left;
    margin-bottom: 50px;

    .title {
        text-align: center;
    }
    
    article {
        line-height: 1.3;
        
        b, strong {
            color: $blue;
            font-weight: bold;
        }

        code {
            background-color: $mediumgray;
            padding: 0 3px;
            border-radius: 3px;
            font-weight: bold;
        }

        pre>code {
            background-color: inherit;
            padding: 0;
            border-radius: inherit;
            font-weight: inherit;
        }
    }

    figure {
        img {
            border: 1px solid $darkblue;
            width: 100%;
        }

        figcaption {
            font-size: 80%;
            font-style: italic;
        }
    }

    .tip {
        color: $blue;
        font-size: 120%;
        font-style: italic;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        margin: 1em 0;

        @media screen and (max-width: 767px) {
            display: block;
            text-align: center;
        }

        img {
            margin-top: -30px;
            vertical-align: bottom;
            border-bottom: 1px solid $blue;
        }

        div {
            padding: 0 10px;
        }
    }

    .date {
        font-size: 90%;
        font-style: italic;
        text-align: right;
    }
}

section.facts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;

    div {
        width: 30%;
        margin: 10px 0;

        @media screen and (max-width: 767px) {
            width: 45%;
        }

        .title {
            display: block;
            font-weight: bold;
            color: $blue;
        }
    }
}

section.gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    figure {
        margin: 10px;
    }
}

section.actors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    figure {
        margin: 10px;
        width: 30%;

        figcaption {
            font-size: 80%;
            font-style: italic;
        }
    }
}

section.contact {
    .socials {
        img {
            height: 64px;
            width: auto;
            margin: 10px 25px;
            vertical-align: top;
        }

        .discord img {
            height: 70px;
        }

        .twitch img {
            height: 70px;
        }
    }

    .subscribe {
        border-radius: 5px;
        background-color: rgba(0,0,0,0.2);
        padding: 10px 25px 25px 25px;
        margin: 50px 100px;

        @media screen and (max-width: 767px) {
            margin: 50px 20px;
        }
    }

    .button {
        border: 1px solid $blue;
        border-radius: 3px;
        padding: 10px 25px;
        text-decoration: none;

        &:hover {
            color: #fff;
            background-color: $blue;
        }
    }
}

.col2 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
    }

    &>div {
        width: 48%;
        border: 1px solid $blue;
        margin: 10px 0;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
}

div.highlight {
    pre {
        white-space: pre-wrap;
        padding: 5px;
    }

    code {
        font-size: 80%;
    }
}

section.list {
    text-align: left;
    line-height: 1.3;

    h2 {
        margin: 0;
        font-family: "Share", sans-serif;
        font-weight: bold;
    }
    
    p {
        margin-top: 0;
    }

    .date {
        font-weight: normal;
        color: $lightgray;
    }
}

.tagline {
    margin: 0;
}

section.aaign {
    color: $nearblack;
    margin: 0 0 25px 0;
    position: relative;
    z-index: 10;

    img {
        width: 100%;
        display: block;
    }

    a {
        display: block;
        color: #fff;
        text-decoration: none;
    }

    .title {
        margin: 0;
        color: #fff;
        background-color: $red;
        padding: 5px;
    }
}

body.docs {
    #bg { display: none }
    .cta { display: none }

    header {
        margin-bottom: 20px;
    }

    .content {
        text-align: left;

        h1 { text-align: left; }
        h1.title { margin-top: 10px; }

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    section.post {
        flex: 1;

        figure.entity {
            display: inline-block;
            margin: 0 20px;

            img {
                border: 0;
            }

            figcaption {
                font-size: 80%;
                font-style: italic;
            }
        }
    }

    section.post nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1em 0 0 0;

        a:last-child {
            text-align: right;
        }
    }

    nav#TableOfContents {
        margin: 1em 0;
        ul:first-child { margin: 0; }
    }

    nav.docs {
        margin: 0 25px 0 0;
        padding: 10px 25px 0 0;
        border-right: 2px solid $darkblue;
        text-align: right;
        width: 20%;
        min-width: 100px;

        @media screen and (max-width: 767px) {
            border: none;
            width: 100%;
            text-align: center;
            padding: 10px;
        }

        ul, li {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            margin: 10px 0;

            .selected {
                color: $red;
            }
        }
    }
}